<template>
  <div>
    <div class="header-notice" ref="scrollView" v-if="stepConfig.head_notice">
      {{ stepConfig.head_notice }}
    </div>
    <div class="card">
      <form @submit.prevent="createLead">
        <div class="card__inner">
          <h3 v-if="stepConfig.title" style="text-align: center">
            {{ stepConfig.title }}
          </h3>
          <h4 v-if="stepConfig.subtitle" style="text-align: center">
            {{
              stepConfig.subtitle
                ? stepConfig.subtitle
                : "Wer soll die Angebote erhalten?"
            }}
          </h4>

          <div
            v-for="(field, key) in stepConfig.fields"
            :key="'stepconfig-' + key"
          >
            <div
              v-if="field == 'name' || (field && field.type == 'name')"
              class="card__input-row"
            >
              <input
                type="text"
                class="full-size input"
                :required="getRequired(field)"
                v-model="stepData.contact_name"
                :placeholder="getPlaceholder(field, 'Name')"
              />
            </div>
            <div
              v-if="field == 'firma' || (field && field.type == 'firma')"
              class="card__input-row"
            >
              <input
                type="text"
                class="full-size input"
                :required="getRequired(field)"
                v-model="stepData.firma"
                :placeholder="getPlaceholder(field, 'Firma')"
              />
            </div>
            <div
              v-if="field == 'email' || (field && field.type == 'email')"
              class="card__input-row"
            >
              <input
                type="email"
                class="full-size input"
                :required="getRequired(field)"
                v-model="stepData.contact_email"
                :placeholder="
                  getPlaceholder(field, 'E-Mail (für die Angebote)')
                "
              />
            </div>
            <div
                v-if="field == 'date_of_birth'"
                class="card__input-row"
            >
              <input
                  type="date"
                  class="full-size input font-sans"
                  :required="getRequired(field)"
                  v-model="stepData.date_of_birth"
                  :placeholder="
                  getPlaceholder(field, 'Geburtsdatum')
                "
              />
            </div>
            <div
              v-if="field == 'telefon' || (field && field.type == 'telefon')"
              class="card__input-row"
            >
              <input
                id="field-contact-tel"
                type="tel"
                class="full-size input"
                :required="getRequired(field)"
                @input="validatePhoneNumber"
                v-model="stepData.contact_tel"
                :placeholder="
                  getPlaceholder(field, 'Telefon (für eventuelle Rückfragen)')
                "
              />
            </div>
            <div
              v-if="field == 'telefon' && additionalPhoneField > 0"
              class="card__input-row"
            >
              <input
                id="field-contact-mobile"
                type="tel"
                class="full-size input"
                :required="additionalPhoneField === 2"
                v-model="stepData.contact_mobile"
                @input="validatePhoneNumber"
                :placeholder="
                  'Mobilnummer ' +
                  (additionalPhoneField == 2 ? '*' : '(Optional)')
                "
              />
            </div>
            <div v-if="field == 'street'" class="card__input-row">
              <input
                type="text"
                class="full-size input"
                required="getRequired(field)"
                v-model="stepData.contact_street"
                :placeholder="getPlaceholder(field, 'Straße')"
              />
            </div>
          </div>
          <div v-if="additionalStreetField > 0" class="card__input-row">
            <input
              type="text"
              class="full-size input"
              :required="additionalStreetField === 2"
              v-model="stepData.contact_street"
              :placeholder="
                'Straße + Nr ' +
                (additionalStreetField == 2 ? '*' : '(Optional)')
              "
            />
          </div>

          <div class="card__input-row">
            <div class="row-agb">
              <input id="check-agb" name="check-agb" type="checkbox" class="input-cb" required />
              <label for="check-agb">
                Ja, ich stimme der Datenschutzerklärung und den AGB zu.
                (Widerruf jederzeit möglich)
              </label>
            </div>

          </div>

          <div class="card__input-row" v-if="direct_partner">
            <div class="row-agb">
              <input id="check-direct-partner" name="check-direct-partner" type="checkbox" class="input-cb" required>
              <label for="check-direct-partner">
                <span v-if="stepConfig?.direct_partner_optin">
                  {{ stepConfig.direct_partner_optin }}
                </span>
                <span v-else>
                  Platzhalter Direct Partner Opt In
                </span>
              </label>
            </div>
          </div>

          <div class="card__input-row">
            <button
              :disabled="processing"
              class="full-size btn btn--angebote btn--notice"
              type="submit"
            >
            <div v-if="processing === false">
              <div>
                {{
                  stepConfig.btn_title
                    ? stepConfig.btn_title
                    : "Angebote erhalten"
                }}
              </div>
              <div class="text-small">
                {{
                  stepConfig.btn_subtitle
                    ? stepConfig.btn_subtitle
                    : "kostenlos & unverbindlich"
                }}
              </div>
            </div>
              <div v-if="processing === true" class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </button>
          </div>
          <div class="card__input-row row-newsletter-info">
            Wir können Ihnen Informationen über ähnliche Produkte und Leistungen
            per E-Mail zusenden. Ein Widerspruch ist jederzeit
            <a href="mailto:info@einfach-zum-angebot.de">hier</a> möglich.
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Tracking from "../../helpers/Tracking.js";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js/max";

export default {
  name: "DataStep",
  props: ["config", "stepConfig", "data", "isActive"],
  emits: ["nextStep"],

  data: () => {
    return {
      processing: false,
      direct_partner: false,
      additionalPhoneField:
        window.location.search.indexOf("apf=1") >= 0
          ? 1
          : window.location.search.indexOf("apf=2") >= 0
          ? 2
          : 0,
      additionalStreetField:
        window.location.search.indexOf("asf=1") >= 0
          ? 1
          : window.location.search.indexOf("asf=2") >= 0
          ? 2
          : 0,
      validatePhoneField:
        window.location.search.indexOf("vpf=1") >= 0
          ? 1
          : window.location.search.indexOf("vpf=2") >= 0
          ? 2
          : 0,
      stepData: {
        contact_name: "",
        firma: "",
        contact_email: "",
        contact_tel: "",
        contact_mobile: "",
      },
    };
  },
  watch: {
    isActive(newVal) {
      if (newVal) {
        Tracking.sendPageView(this.config, this.stepConfig);
      }
      if (newVal) {
        this.direct_partner = window?.direct_partner ?? false;
        window.setTimeout(() => {
          let match = window.matchMedia("(max-width: 600px)");
          if (this.$refs.scrollView && match && match.matches) {
            window.scrollViewJuwe = this.$refs.scrollView;
            this.$refs.scrollView.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
        }, 100);
      }
    },
  },
  methods: {
    getPlaceholder(field, value) {
      value = field && field.placeholder ? field.placeholder : value;
      if (this.getRequired(field)) {
        value += " *";
      }
      return value;
    },
    getRequired(field) {
      if (field.required !== undefined) {
        if (!field.required) return false;
      }

      return true;
    },
    createLead() {
      if(this.processing) return;
      this.processing = true;

      let data = {
        type: this.config.type,
        name: "",
        vorname: "",
        street: "",
        zip: "",
        city: "",
        email: "",
        tel: "",
        perMobile: "0",
        data: {},
        tracking_data: window.trackingData ? window.trackingData : {},
        utm: window.utm ? window.utm : {},
      };

      if(this.direct_partner){
        data.direct_partner = true;
      }

      if('target_country' in this.config){
        data.contact_country = this.config.target_country;
      }

      let rawData = this.data;
      for (let k in JSON.parse(JSON.stringify(this.stepData))) {
        rawData[k] = this.stepData[k];
      }

      if (rawData["contact_zip"]) {
        data["zip"] = rawData["contact_zip"];
        delete rawData["contact_zip"];
      }

      if (rawData["contact_name"]) {
        data["name"] = rawData["contact_name"];

        delete rawData["contact_name"];
      }

      if (rawData["contact_vorname"]) {
        data["name"] = rawData["contact_vorname"];
        delete rawData["contact_vorname"];
      }

      if (rawData["contact_street"]) {
        data["street"] = rawData["contact_street"];
        delete rawData["contact_street"];
      }

      if (rawData["contact_city"]) {
        data["city"] = rawData["contact_city"];
        delete rawData["contact_city"];
      }

      if (rawData["contact_email"]) {
        data["email"] = rawData["contact_email"];
        delete rawData["contact_email"];
      }

      if (rawData["contact_tel"]) {
        data["tel"] = rawData["contact_tel"];
        delete rawData["contact_tel"];
      }

      if (rawData["contact_mobile"]) {
        data["mobile"] = rawData["contact_mobile"];
        delete rawData["contact_mobile"];
      }

      data.data = rawData;

      fetch(
        "https://einfach-zum-angebot.de/api.php?key=owZ8injKqTGd8T6EY6wGedSUB6yrBqkv",
        {
          method: "POST",
          mode: "cors",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((resp) => {
          return resp.json();
        })
        .then((json) => {
          if (json.status != "SUCCESS") {
            this.processing = false;
            throw "Error";
          }
          if (json.status == "SUCCESS" && json.leadID) {
            window.localStorage.setItem("lead-id", json.leadID);
            if (window["dataLayer"]) {
              window["dataLayer"].push({
                conversionType: this.config.type.toUpperCase(),
                event: "conversion",
              });
            }
          }

          this.$emit("nextStep", {});
          this.processing = false;
        })
        .catch(() => {
          alert(
            "Wir haben leider einen Fehler erhalten. Bitte kontaktieren Sie uns telefonisch."
          );
        });
    },
    validatePhoneNumber: function (event) {
      if (event.target.value === "" && this.additionalPhoneField === 1) {
        event.target.setCustomValidity("");
        return true;
      }
      if (!isValidPhoneNumber(String(event.target.value), "DE")) {
        switch (this.validatePhoneField) {
          case 0:
            return true;
          case 1:
            if (event.target.id === "field-contact-tel") {
              event.target.setCustomValidity(
                "Falsche Telefonnummer, bitte prüfen!"
              );
              return false;
            }
            break;
          case 2:
            if (event.target.id === "field-contact-tel") {
              event.target.setCustomValidity(
                "Falsche Telefonnummer, bitte prüfen!"
              );
              return false;
            }
            if (event.target.id === "field-contact-mobile") {
              event.target.setCustomValidity(
                "Falsche Mobilnummer, bitte prüfen!"
              );
              return false;
            }
            break;
        }
      } else {
        event.target.setCustomValidity("");
        return true;
      }
    },
  },
};
</script>

<style scoped>
.font-sans{
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.btn--angebote {
  font-size: 23px;
  padding: 20px 15px;
  background: #4caf50;
}

.btn--angebote .text-small {
  font-size: 0.6em;
  font-weight: normal;
  margin-top: 5px;
}

.row-agb {
  display: flex;
  font-size: 12px;
  align-items: center;
  margin: 15px 0;
}

.row-newsletter-info {
  font-size: 11px;
  opacity: 0.5;
  line-height: 1.3em;
}

.input-cb {
  min-width: 20px;
  min-height: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.card__input-row {
  margin-bottom: 10px;
}

.card__input-row input {
  padding: 15px 10px;
}
.full-size {
  width: 100%;
  box-sizing: border-box;
}

h3 {
  margin-top: 0;
}
.map {
  width: 100%;
  height: auto;
}

.map path {
  fill: #d5dbde;
}

.card {
  max-width: 100%;
  box-sizing: border-box;
  width: 550px;
}

.card__inner {
  max-width: 360px;
  margin: auto;
}

.card h3 {
  color: #356a34;
  font-size: 23px;
  font-weight: normal;
  text-align: center;
}

.card__row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__row > div {
  width: 50%;
}

.header-notice {
  top: -50px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  background: #c6dea7;
  border-bottom: 1px solid #a0ca62;
  color: #3d6a01;
  padding: 15px;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: -50px;
}

.header-notice:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 23px 41px 0 41px;
  margin-left: -41px;
  border-color: #a0ca62 transparent transparent transparent;
}

.header-notice:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 23px 41px 0 41px;
  margin-left: -41px;
  margin-top: -1px;
  border-color: #c8dfab transparent transparent transparent;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 500px) {
  .card {
    padding: 30px;
  }
  .btn--angebote {
    font-size: 20px;
  }
}
</style>
